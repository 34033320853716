<template>
	<div class="detailPage">
		<div class="detailContent">
			<el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
				label-width="110px">
				<!-- TODO: 这里需要改成图片上传 -->
				<el-form-item label="头像" prop="avatar">
					<el-upload
						action=""
						class="avatar-uploader"
						:show-file-list="false"
						accept="image/jpeg,image/png"
						:disabled="editType === 3"
						:on-change="onUploadChange"
						:auto-upload="false"
						>
						<img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon" ></i>
					</el-upload>
					<!-- :on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload"
						:headers="uploadHeaders" -->
					<!-- <img v-if="dataForm.avatar" :src="dataForm.avatar" class="avatar"> -->
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input :disabled="editType === 3" v-model="dataForm.name" placeholder="请输入医生姓名"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select :disabled="editType === 3" v-model="dataForm.gender" placeholder="请选择性别" clearable>
						<el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="手机号" prop="mobilePhone">
					<el-input :disabled="editType === 3" v-model="dataForm.mobilePhone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="身份证号" prop="idCard">
					<el-input :disabled="editType === 3" v-model="dataForm.idCard" placeholder="请输入身份证号"></el-input>
				</el-form-item>
				<el-form-item label="所属医院" prop="hospitalName">
					<el-input :disabled="editType === 3" v-model="dataForm.hospitalName" placeholder="请输入所属医院"></el-input>
				</el-form-item>
				<el-form-item label="科室" prop="deptId">
					<el-select :disabled="editType === 3" v-model="dataForm.deptId" @change="changeDept" placeholder="请选择科室" clearable>
						<el-option v-for="item in deptList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="医生职称" prop="titleId">
					<el-select :disabled="editType === 3" v-model="dataForm.titleId" @change="changeTitle" placeholder="请选择职称" clearable>
						<el-option v-for="item in titleList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="中药提成比例" prop="percentage">
					<el-input-number :disabled="editType === 3" v-model="dataForm.percentage" :precision="2" :step="0.01" :max="1" size="large" placeholder="示例：0.25"></el-input-number>
				</el-form-item>
				<el-form-item label="诊费提成比例" prop="outpatientCarePercentage">
					<el-input-number :disabled="editType === 3" v-model="dataForm.outpatientCarePercentage" :precision="2" :step="0.01" :max="1" size="large" placeholder="示例：0.25"></el-input-number>
				</el-form-item>
				<el-form-item label="擅长领域" prop="goodAt">
					<el-input :disabled="editType === 3" v-model="dataForm.goodAt" type="textarea" placeholder="请填写擅长领域，100字以内"></el-input>
				</el-form-item>
				<el-form-item label="个人简介" prop="description">
					<el-input :disabled="editType === 3" v-model="dataForm.description" type="textarea" placeholder="请输入个人简介，200字以内"></el-input>
				</el-form-item>
				<el-form-item label="权重" prop="weight">
					<el-input :disabled="editType === 3" v-model="dataForm.weight"  placeholder="请填写权重"></el-input>
				</el-form-item>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
				<el-form-item label="医生资格证" prop="imgUrl1">
					<el-upload
						class="avatar-uploader"
						action=""
						list-type="picture-card"
						:limit="3"
						ref="uploadImg1"
						:disabled="editType === 3"
						:on-preview="handlePreview"
						:file-list="img1List"
						:on-remove="removeImg1List"
						:on-change="handleImg1Success"
						:auto-upload="false"
						accept="image/jpeg,image/png"
						:headers="uploadHeaders"
					>
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<div slot="tip" class="el-upload__tip">需包含公章、编码、个人照片，最多上传3张</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="执业证" prop="imgUrl2">
					<el-upload
						class="avatar-uploader"
						action=""
						list-type="picture-card"
						:limit="3"
						:disabled="editType === 3"
						:on-preview="handlePreview"
						:file-list="img2List"
						:auto-upload="false"
						:on-remove="removeImg2List"
						:on-change="handleImg2Success"
						accept="image/jpeg,image/png"
						:headers="uploadHeaders"
					>
						<i class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item v-if="editType !== 3" class="buttons">
					<el-button @click="back()">取消</el-button>
					<el-button type="primary" @click="dataFormSubmit()">提交</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { validatePhone, validateIdCard } from '../../utils';
import OSS from 'ali-oss';
import md5 from 'md5';

export default {
	name: 'doctorOption',
	data () {
		return {
			editType: 1, // 1-新增 2-修改 3-查看
			dataForm: {
				name: '',
				description: '',
				weight: 0,
				percentage: 0,
				outpatientCarePercentage: 0,
				avatar: '',
				imgUrl1: '',
				imgUrl2: '',
				imgUrl3: '',
				imgUrl4: '',
				imgUrl5: '',
				imgUrl6: ''
			},
			img1List: [],
			img2List: [],
			dataRule: {
				/* avatar: [
					{ required: true, message: '请上传医生头像', trigger: 'blur' }
				], */
				imgUrl1: [
					{ required: true, message: '医生资格证或执业证至少上传1张', trigger: 'blur' }
				],
				name: [
					{ required: true, message: '姓名不能为空', trigger: 'blur' }
				],
				gender: [
					{ required: true, message: '性别不能为空', trigger: 'blur' }
				],
				mobilePhone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' },
					{ validator: validatePhone, trigger: 'blur' }
				],
				idCard: [
					{ required: true, message: '身份证号不能为空', trigger: 'blur' },
					{ validator: validateIdCard, trigger: 'blur' }
				],
				deptId: [
					{ required: true, message: '科室不能为空', trigger: 'blur' }
				],
				titleId: [
					{ required: true, message: '职称不能为空', trigger: 'blur' }
				],
				goodAt: [
					{ required: true, message: '擅长领域不能为空', trigger: 'blur' }
				]/* ,
				description: [
					{ required: true, message: '个人简介不能为空', trigger: 'blur' }
				] */
			},
			genderList: [{
				label: '男',
				value: 1
			},
			{
				label: '女',
				value: 2
			}
			],
			hostpitalList: [],
			deptList: [],
			titleList: [],
			dialogImageUrl: '',
			dialogVisible: false,
			uploadUrl: process.env.VUE_APP_BASE_URL + '/mnt/upload-file',
			uploadHeaders: {}
		};
	},
	beforeCreate () {
		if (+this.$route.query.editType === 3) {
			this.$route.meta.navName = '查看医生';
		} else if (+this.$route.query.editType === 2) {
			this.$route.meta.navName = '修改医生';
		} else {
			this.$route.meta.navName = '新增医生';
		}
	},
	created () {
		this.getDepts();
		this.getTitleList();
		const applyId = this.$route.query.applyId;
		const editType = +this.$route.query.editType;
		if (editType) this.editType = editType;
		if (applyId) {
			this.dataForm.applyId = applyId;
			this.getDetail(applyId);
		}
	},
	methods: {
		back () {
			this.$router.go(-1);
		},
		getDepts () {
			this.$root.request('deptList', {
				page: 1,
				limit: 1000
			}).then((data) => {
				if (data) {
					this.deptList = data.rows.map(r => {
						return {
							label: r.name,
							value: r.id
						};
					});
				}
			});
		},
		getTitleList () {
			this.$root.request('titleList', {}).then((data) => {
				if (data) {
					this.titleList = data.map(item => {
						return {
							label: item.name,
							value: item.id
						};
					});
				}
			});
		},
		getDetail (applyId) {
			this.$root.request('doctorDetail', {
				applyId
			}).then((data) => {
				if (data) {
					this.dataForm = { ...data, name: data.userName, applyId: data.id };
					if (data.imgUrl1) this.img1List.push({ url: data.imgUrl1, response: { data: { url: data.imgUrl1 } } });
					if (data.imgUrl2) this.img1List.push({ url: data.imgUrl2, response: { data: { url: data.imgUrl2 } } });
					if (data.imgUrl3) this.img1List.push({ url: data.imgUrl3, response: { data: { url: data.imgUrl3 } } });
					if (data.imgUrl4) this.img2List.push({ url: data.imgUrl4, response: { data: { url: data.imgUrl4 } } });
					if (data.imgUrl5) this.img2List.push({ url: data.imgUrl5, response: { data: { url: data.imgUrl5 } } });
					if (data.imgUrl6) this.img2List.push({ url: data.imgUrl6, response: { data: { url: data.imgUrl6 } } });
				}
			});
		},
		// 表单提交
		dataFormSubmit () {
			this.$refs.dataForm.validate((valid) => {
				if (valid) {
					this.dataForm.weight = +this.dataForm.weight;
					this.$root.request('doctorEditApply', this.dataForm).then((data) => {
						if (data) {
							this.$root.elMsg('操作成功', 'success', () => {
								if (this.$route.query.applyId) {
									this.back();
								} else {
									this.img1List = [];
									this.img2List = [];
									this.$refs.dataForm.resetFields();
								}
							}, 1000);
						}
					}).catch(e => {
						this.$root.elMsg('操作失败', 'error');
					});
				}
			});
		},
		beforeAvatarUpload (file) {
			const isLt2M = file.size / 1024 < 200;
			if (['image/jpeg', 'image/png'].indexOf(file.type) === -1) {
				this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 200k!');
			}
			return isLt2M;
		},
		getTokenAndUpload (file, cb) {
			this.$root.request('getOSSToken', {
				page: 1,
				limit: 1000
			}).then((data) => {
				file.status = 'uploading';
				const imgType = file.raw.type.split('/')[1];
				const filename = file.raw.name + file.raw.size; // md5对图片名称进行加密
				const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
				const client = new OSS({
					region: data.region,
					stsToken: data.SecurityToken,
					accessKeyId: data.AccessKeyId,
					accessKeySecret: data.AccessKeySecret,
					bucket: data.bucket
				});
				client
					.put('odat/mnt/icons/' + keyValue, file.raw)
					// .multipartUpload(keyValue, file.file)
					.then((res) => {
						if (res.url) {
							file.url = res.url;
							file.status = 'uploaded';
							file.message = '';
							cb(res);
						} else {
							file.status = 'failed';
							this.$root.elMsg('图片上传失败', 'error');
							cb();
						}
					})
					.catch((err) => {
						console.log('message', err.message);
						file.status = 'failed';
						this.$root.elMsg('图片上传失败', 'error');
						cb();
					});
			});
		},
		onUploadChange (file) {
			this.getTokenAndUpload(file, (res) => {
				if (res) {
					this.dataForm.avatar = res.url;
				}
			});
		},
		handlePreview (file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleImg1Success (file) {
			this.getTokenAndUpload(file, (res) => {
				if (res) {
					this.img1List.push({ url: res.url, response: { data: { url: res.url } } });
					this.dataForm.imgUrl1 = this.img1List[0].response.data.url;
					if (this.img1List.length > 1) this.dataForm.imgUrl2 = this.img1List[1].response.data.url;
					if (this.img1List.length > 2) this.dataForm.imgUrl3 = this.img1List[2].response.data.url;
				}
			});
		},
		removeImg1List (file, fileList) {
			this.img1List = fileList.slice(-3);
			this.dataForm.imgUrl1 = this.img1List[0].response.data.url;
			if (this.img1List.length > 1) this.dataForm.imgUrl2 = this.img1List[1].response.data.url;
			if (this.img1List.length > 2) this.dataForm.imgUrl3 = this.img1List[2].response.data.url;
		},
		removeImg2List (file, fileList) {
			this.img2List = fileList.slice(-3);
			this.dataForm.imgUrl4 = this.img2List[0].response.data.url;
			if (this.img2List.length > 1) this.dataForm.imgUrl5 = this.img2List[1].response.data.url;
			if (this.img2List.length > 2) this.dataForm.imgUrl6 = this.img2List[2].response.data.url;
		},
		handleImg2Success (file) {
			this.getTokenAndUpload(file, (res) => {
				if (res) {
					this.img2List.push({ url: res.url, response: { data: { url: res.url } } });
					this.dataForm.imgUrl4 = this.img2List[0].response.data.url;
					if (this.img2List.length > 1) this.dataForm.imgUrl5 = this.img2List[1].response.data.url;
					if (this.img2List.length > 2) this.dataForm.imgUrl6 = this.img2List[2].response.data.url;
				}
			});
		},
		changeDept (val) {
			var obj = {};
			obj = this.deptList.find(function (item) {
				return item.value === val;
			});
			this.dataForm.deptName = obj.label;
		},
		changeTitle (val) {
			var obj = {};
			obj = this.titleList.find(function (item) {
				return item.value === val;
			});
			this.dataForm.titleName = obj.label;
		}
	}
};
</script>
<style lang="less" scoped>
	/deep/ .el-input, .el-input-number,
	.el-textarea {
		min-width: 220px;
		width: auto !important;
	}

	/deep/ .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		line-height: 78px;
		text-align: center;
	}

	.avatar {
		width: 78px;
		height: 78px;
		display: block;
	}
</style>