var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailPage" }, [
    _c(
      "div",
      { staticClass: "detailContent" },
      [
        _c(
          "el-form",
          {
            ref: "dataForm",
            attrs: {
              model: _vm.dataForm,
              rules: _vm.dataRule,
              "label-width": "110px"
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.dataFormSubmit()
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "头像", prop: "avatar" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "",
                      "show-file-list": false,
                      accept: "image/jpeg,image/png",
                      disabled: _vm.editType === 3,
                      "on-change": _vm.onUploadChange,
                      "auto-upload": false
                    }
                  },
                  [
                    _vm.dataForm.avatar
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.dataForm.avatar }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "姓名", prop: "name" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    placeholder: "请输入医生姓名"
                  },
                  model: {
                    value: _vm.dataForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "name", $$v)
                    },
                    expression: "dataForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "性别", prop: "gender" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      disabled: _vm.editType === 3,
                      placeholder: "请选择性别",
                      clearable: ""
                    },
                    model: {
                      value: _vm.dataForm.gender,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "gender", $$v)
                      },
                      expression: "dataForm.gender"
                    }
                  },
                  _vm._l(_vm.genderList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "手机号", prop: "mobilePhone" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    placeholder: "请输入手机号"
                  },
                  model: {
                    value: _vm.dataForm.mobilePhone,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "mobilePhone", $$v)
                    },
                    expression: "dataForm.mobilePhone"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "身份证号", prop: "idCard" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    placeholder: "请输入身份证号"
                  },
                  model: {
                    value: _vm.dataForm.idCard,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "idCard", $$v)
                    },
                    expression: "dataForm.idCard"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "所属医院", prop: "hospitalName" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    placeholder: "请输入所属医院"
                  },
                  model: {
                    value: _vm.dataForm.hospitalName,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "hospitalName", $$v)
                    },
                    expression: "dataForm.hospitalName"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "科室", prop: "deptId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      disabled: _vm.editType === 3,
                      placeholder: "请选择科室",
                      clearable: ""
                    },
                    on: { change: _vm.changeDept },
                    model: {
                      value: _vm.dataForm.deptId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "deptId", $$v)
                      },
                      expression: "dataForm.deptId"
                    }
                  },
                  _vm._l(_vm.deptList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "医生职称", prop: "titleId" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      disabled: _vm.editType === 3,
                      placeholder: "请选择职称",
                      clearable: ""
                    },
                    on: { change: _vm.changeTitle },
                    model: {
                      value: _vm.dataForm.titleId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "titleId", $$v)
                      },
                      expression: "dataForm.titleId"
                    }
                  },
                  _vm._l(_vm.titleList, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "中药提成比例", prop: "percentage" } },
              [
                _c("el-input-number", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    precision: 2,
                    step: 0.01,
                    max: 1,
                    size: "large",
                    placeholder: "示例：0.25"
                  },
                  model: {
                    value: _vm.dataForm.percentage,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "percentage", $$v)
                    },
                    expression: "dataForm.percentage"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "诊费提成比例",
                  prop: "outpatientCarePercentage"
                }
              },
              [
                _c("el-input-number", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    precision: 2,
                    step: 0.01,
                    max: 1,
                    size: "large",
                    placeholder: "示例：0.25"
                  },
                  model: {
                    value: _vm.dataForm.outpatientCarePercentage,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "outpatientCarePercentage", $$v)
                    },
                    expression: "dataForm.outpatientCarePercentage"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "擅长领域", prop: "goodAt" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    type: "textarea",
                    placeholder: "请填写擅长领域，100字以内"
                  },
                  model: {
                    value: _vm.dataForm.goodAt,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "goodAt", $$v)
                    },
                    expression: "dataForm.goodAt"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "个人简介", prop: "description" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    type: "textarea",
                    placeholder: "请输入个人简介，200字以内"
                  },
                  model: {
                    value: _vm.dataForm.description,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "description", $$v)
                    },
                    expression: "dataForm.description"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "权重", prop: "weight" } },
              [
                _c("el-input", {
                  attrs: {
                    disabled: _vm.editType === 3,
                    placeholder: "请填写权重"
                  },
                  model: {
                    value: _vm.dataForm.weight,
                    callback: function($$v) {
                      _vm.$set(_vm.dataForm, "weight", $$v)
                    },
                    expression: "dataForm.weight"
                  }
                })
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: { visible: _vm.dialogVisible },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisible = $event
                  }
                }
              },
              [
                _c("img", {
                  attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                })
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "医生资格证", prop: "imgUrl1" } },
              [
                _c(
                  "el-upload",
                  {
                    ref: "uploadImg1",
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "",
                      "list-type": "picture-card",
                      limit: 3,
                      disabled: _vm.editType === 3,
                      "on-preview": _vm.handlePreview,
                      "file-list": _vm.img1List,
                      "on-remove": _vm.removeImg1List,
                      "on-change": _vm.handleImg1Success,
                      "auto-upload": false,
                      accept: "image/jpeg,image/png",
                      headers: _vm.uploadHeaders
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon"
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip"
                      },
                      [_vm._v("需包含公章、编码、个人照片，最多上传3张")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "执业证", prop: "imgUrl2" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "",
                      "list-type": "picture-card",
                      limit: 3,
                      disabled: _vm.editType === 3,
                      "on-preview": _vm.handlePreview,
                      "file-list": _vm.img2List,
                      "auto-upload": false,
                      "on-remove": _vm.removeImg2List,
                      "on-change": _vm.handleImg2Success,
                      accept: "image/jpeg,image/png",
                      headers: _vm.uploadHeaders
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-plus avatar-uploader-icon"
                    })
                  ]
                )
              ],
              1
            ),
            _vm.editType !== 3
              ? _c(
                  "el-form-item",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.back()
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.dataFormSubmit()
                          }
                        }
                      },
                      [_vm._v("提交")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }